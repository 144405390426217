import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import logo from './img/logo.png';
import { FiFacebook } from "react-icons/fi";
import { AiOutlineYoutube } from "react-icons/ai";
import { GoGlobe } from "react-icons/go";
import { FaInstagram } from "react-icons/fa";
import CurrencyInput from 'react-currency-input-field';
import PhoneInput from 'react-phone-number-input/input'
import { getCountryCallingCode } from 'react-phone-number-input'


const App = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [message, setMessage] = useState('');
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    

    emailjs
      .sendForm('service_dsybh3h', 'template_w56ltv9', form.current, {
        publicKey: '912NK0mef3gp97c6v',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setIsSubmitted(true);
          //window.location.reload();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };


  const [value, setValue] = useState()
  return (
    <div className="main">
      <div className="bio">
        <img className="profile-img" src={logo} alt="PCodnowa Logo" />
        <h3 className="header">PCodnowa</h3>
        <a className="fa fa-facebook" href="https://www.facebook.com/PCodnowa" target="_blank" style={{ color: 'white' }}><FiFacebook /> Facebook</a><br />
        <a className="fa fa-instagram" href="https://www.instagram.com/pcodnowa/" target="_blank" style={{ color: 'white' }}><FaInstagram /> Instagram</a><br/>
        <a className="fa fa-youtube" href="https://www.youtube.com/@pcodnowa" target="_blank" style={{ color: 'white' }}><AiOutlineYoutube /> YouTube</a><br />
        <a href="https://pcodnowa.pl" target="_blank" style={{ color: 'white' }}><i className="fa fa-home" aria-hidden="true"></i><GoGlobe /> PCodnowa.pl</a>
        <p className='about'> Jeżeli nie posiadasz preferencji odnośnie producentów, ani pojemności dysków możesz zostawić to nam, przygotujemy najbardziej opłacalną ofertę w wybranym budżecie. Wymagane pola to główne zastosowanie komputera, budżet oraz e-mail. Warto też zastanowić się jaka przestrzeń dyskowa będzie potrzebna, jeżeli nic nie zostanie wybrane przygotujemy propozycję z dyskiem 1TB M.2.</p>
      </div>
      <div className="contact" style={{ marginTop: '-10px' }}>
      {isSubmitted ? (
        <p className='aftersend'>Dziękujemy za przesłanie zapytania, odpowiemy najszybciej jak to możliwe.</p>
      ) : (
        <form ref={form} onSubmit={sendEmail}>
        <fieldset>
        <legend className="header">Wyceń swój nowy komputer</legend>
            <label className="fa fa-user" htmlFor="name-input" aria-hidden="true"></label>
            <input
              type="text"
              placeholder="Imię"
              id="name-input"
              name='user_name'
            />
          </fieldset>
          <fieldset>
            <label className="fa fa-envelope" htmlFor="email-input" aria-hidden="true"></label>
            <input
              type="email"
              placeholder="e-mail"
              id="email-input"
              name='user_email'
              required
            />
          </fieldset>
          <fieldset>
            <label className="fa fa-envelope" htmlFor="nr-input" aria-hidden="true"></label>
            <PhoneInput
              country="pl"
              id="email-input"
              name='phonenumber'
              placeholder="Numer telefonu"
              maxlength="9"
              value={value}
              onChange={setValue} 
            />
          </fieldset>
          <fieldset>
            <label className="fa fa-envelope" htmlFor="budzet" aria-hidden="true"></label>
            <CurrencyInput
              id="budzet"
              suffix=" ZŁ"
              name="budzet"
              placeholder="Budżet na komputer"
              decimalsLimit={2}
              required
              onValueChange={(value, name, values) => console.log(value, name, values)}
            />
          </fieldset>
          <fieldset>
            <legend>Producent procesora:</legend>
            <select
              id="processor-manufacturer"
              name='cpu_manufacturer'
            >
              <option value="---">---</option>
              <option value="intel">Intel</option>
              <option value="amd">AMD</option>
            </select>
          </fieldset>
          <fieldset>
            <legend>Pojemność dysku:</legend>
            <select
              id="disk-size"
              name='disk_size'
            >
              <option value="---">---</option>
              <option value="250gb">250 GB</option>
              <option value="500gb">500 GB</option>
              <option value="1tb">1 TB</option>
              <option value="2tb">2 TB</option>
            </select>
          </fieldset>
          <fieldset>
            <legend>Producent karty graficznej:</legend>
            <select
              id="graphics-card-manufacturer"
              name='gpu_manufacturer'
            >
              <option value="---">---</option>
              <option value="nvidia">NVIDIA</option>
              <option value="amd">AMD</option>
            </select>
          </fieldset>
          <fieldset>
            <legend>Główne zastosowanie komputera:</legend>
            <select
              id="application-usage"
              name='application_usage'
              required
            >
              <option value="gaming">Gaming</option>
              <option value="office">Biuro</option>
              <option value="pro">Profesjonalne</option>
            </select>
          </fieldset>
          <fieldset>
            <legend>Oświetlenie LED komputera:</legend>
            <select
              id="LED"
              name='LED'
              required
            >
              <option value="null">Brak preferencji</option>
              <option value="tak">Tak</option>
              <option value="niw">Nie</option>
            </select>
          </fieldset>
          <fieldset>
            <legend>Preferowany rodzaj chłodzenia procesora:</legend>
            <select
              id="cpu_cooling"
              name='cpu_cooling'
              required
            >
              <option value="null">Brak preferencji</option>
              <option value="wodne">Wodne</option>
              <option value="powietrzne">Powietrzne</option>
            </select>
          </fieldset>
          <fieldset>
            <legend>System Operacyjny:</legend>
            <select
              id="operating_system"
              name='operating_system'
              required
            >
              <option value="windows11">Windows 11</option>
              <option value="windows10">Windows 10</option>
              <option value="null">Brak</option>
            </select>
          </fieldset>
          <fieldset>
            <label className="fa fa-comment" htmlFor="message-input" aria-hidden="true"></label>
            <textarea
              placeholder="Tutaj wpisz swoje dodatkowe uwagi lub ulubione podzespoły, a uwzględnimy je w wycenie"
              id="message-input"
              style={{ height: '20%' }}
              name='message'
            ></textarea>
          </fieldset>
          <fieldset>
            <button type="submit" value="Send" id="btn-submit">Zapytaj o wycenę</button>
          </fieldset>
        {/*<input type="submit" value="Send" />*/}
      </form>
      )}
      </div>
    </div>
  );
};

export default App;
